import React from 'react';
import { IntlProvider } from 'react-intl';
import { graphql } from 'gatsby';

import Link from '../components/Link';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import BlogSharing from '../components/BlogSharing';
import ContentWrapper from '../components/ContentWrapper';
import BlogLayout from '../components/blogLayout';

import getMessages from '../locales/getMessages';
import { genBlogJsonLd, genBreadcrumbJsonLd } from '../utils/jsonld';

const messages = getMessages();

export default class BlogTemplate extends React.Component {
  componentDidMount() {
    // Track read blog
    fbq('track', 'ViewContent');

    // for disqus
    const d = document;
    const s = d.createElement('script');
    s.src = 'https://evertool-1.disqus.com/embed.js';
    s.setAttribute('data-timestamp', +new Date());
    (d.head || d.body).appendChild(s);
  }

  render() {
    const { data, pageContext } = this.props;
    const { markdownRemark, site } = data; // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark;
    const {
      locale,
      title,
      date,
      path,
      subtitle,
      articleBody,
      keywords,
      datePublished,
      dateCreated,
      dateModified,
      noPromote,
    } = frontmatter;
    const { url } = site.siteMetadata;
    const pageUrl = `${url}${path}`;
    const blogTitle = `${title} - EverTool Blog`;
    const blogJsonLd = genBlogJsonLd({
      url: pageUrl,
      headline: blogTitle,
      alternativeHeadline: subtitle,
      inLanguage: locale,
      articleBody,
      keywords,
      datePublished,
      dateCreated,
      dateModified,
    });

    const breadcrumbJsonLd = genBreadcrumbJsonLd([
      {
        name: 'Blog',
        url,
      },
      {
        name: title,
        url: pageUrl,
      },
    ]);

    return (
      <BlogLayout pageContext={pageContext}>
        <SEO
          title={blogTitle}
          subtitle={subtitle}
          url={pageUrl}
          locale={locale}
          jsonld={[blogJsonLd, breadcrumbJsonLd]}
        />
        <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
          <div>
            <Header />
            <ContentWrapper>
              <div className="breadcrumb-nav">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                  <ul>
                    <li><Link to="/blog">Blog</Link></li>
                    <li className="is-active"><span className="ml2">{title}</span></li>
                  </ul>
                </nav>
              </div>
              <BlogSharing url={pageUrl} text={blogTitle} />
              <div className="blog-markdown-body mt1">
                <div className="blog-post">
                  <h1>{title}</h1>
                  <small>{date}</small>
                  <hr />
                  <article
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <hr />
                  {!noPromote
                    ? (
                      <div className="center">
                        <h3>Join other thousands of happy users to improve your Evernote productivity!</h3>
                        <div className="mt4">
                          <a href="//geo.itunes.apple.com/us/app/evertool/id1396383432?mt=12" target="_blank" rel="noopener noreferrer">
                            <img className="badge" src="/download_mac.svg" alt="download from mac app store" />
                          </a>
                          <a href="//www.microsoft.com/store/apps/9MZWLDGGGW9J?ocid=badge" target="_blank" rel="noopener noreferrer">
                            <img className="badge win" src="/download_windows.png" alt="download from windows store" />
                          </a>
                        </div>
                      </div>
                    )
                    : null}
                </div>
                {!noPromote ? <div id="disqus_thread" className="mt5" /> : null}
              </div>
            </ContentWrapper>
          </div>
          <Footer />
        </IntlProvider>
      </BlogLayout>
    );
  }
}

export const pageQuery = graphql`
  query BlogPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        articleBody
        locale
        keywords
        datePublished
        dateCreated
        dateModified
        noPromote
      }
    }
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
  }
`;
