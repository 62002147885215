import React from 'react';
import styles from './style.module.scss';
import './button.css';

const BlogSharing = ({ url, text }) => (
  <div className={styles.blogSharingWrapper}>
    <p className="pt1">Share</p>
    <a className="resp-sharing-button__link" href={`https://facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener noreferrer" aria-label="">
      <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
        <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm3.6 11.5h-2.1v7h-3v-7h-2v-2h2V8.34c0-1.1.35-2.82 2.65-2.82h2.35v2.3h-1.4c-.25 0-.6.13-.6.66V9.5h2.34l-.24 2z" /></svg>
        </div>
      </div>
    </a>

    <a className="resp-sharing-button__link" href={`https://twitter.com/intent/tweet/?text=${text}&amp;url=${url}`} target="_blank" rel="noopener noreferrer" aria-label="">
      <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
        <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm5.26 9.38v.34c0 3.48-2.64 7.5-7.48 7.5-1.48 0-2.87-.44-4.03-1.2 1.37.17 2.77-.2 3.9-1.08-1.16-.02-2.13-.78-2.46-1.83.38.1.8.07 1.17-.03-1.2-.24-2.1-1.3-2.1-2.58v-.05c.35.2.75.32 1.18.33-.7-.47-1.17-1.28-1.17-2.2 0-.47.13-.92.36-1.3C7.94 8.85 9.88 9.9 12.06 10c-.04-.2-.06-.4-.06-.6 0-1.46 1.18-2.63 2.63-2.63.76 0 1.44.3 1.92.82.6-.12 1.95-.27 1.95-.27-.35.53-.72 1.66-1.24 2.04z" /></svg>
        </div>
      </div>
    </a>

    <a className="resp-sharing-button__link" href={`https://pinterest.com/pin/create/button/?url=${url}&amp;media=${url}&amp;description=${text}`} target="_blank" rel="noopener noreferrer" aria-label="">
      <div className="resp-sharing-button resp-sharing-button--pinterest resp-sharing-button--small">
        <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.38 0 0 5.38 0 12s5.38 12 12 12 12-5.38 12-12S18.62 0 12 0zm1.4 15.56c-1 0-1.94-.53-2.25-1.14l-.65 2.52c-.4 1.45-1.57 2.9-1.66 3-.06.1-.2.07-.22-.04-.02-.2-.32-2 .03-3.5l1.18-5s-.3-.6-.3-1.46c0-1.36.8-2.37 1.78-2.37.85 0 1.25.62 1.25 1.37 0 .85-.53 2.1-.8 3.27-.24.98.48 1.78 1.44 1.78 1.73 0 2.9-2.24 2.9-4.9 0-2-1.35-3.5-3.82-3.5-2.8 0-4.53 2.07-4.53 4.4 0 .5.1.9.25 1.23l-1.5.82c-.36-.64-.54-1.43-.54-2.28 0-2.6 2.2-5.74 6.57-5.74 3.5 0 5.82 2.54 5.82 5.27 0 3.6-2 6.3-4.96 6.3z" /></svg>
        </div>
      </div>
    </a>
  </div>
);

export default BlogSharing;
